'use client';

import type { ChangeEvent, ComponentPropsWithRef, ReactNode } from 'react';
import { forwardRef, useState } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';

import { cn } from '@/lib/utils';

type InputProps = Omit<ComponentPropsWithRef<'input'>, 'size'> &
    VariantProps<typeof inputVariants> & {
        initialValue?: string;
        label?: string;
        icon?: ReactNode;
    };

const inputVariants = tv(
    {
        variants: {
            size: {
                sm: {
                    input: 'px-2 py-1.5 placeholder:text-sm',
                },
                md: {
                    input: 'px-2 py-2 placeholder:text-sm',
                },
                lg: {
                    input: 'px-3 py-2.5 placeholder:text-base',
                },
            },
            label: {
                true: {
                    input: 'placeholder:text-transparent',
                },
            },
        },
        slots: {
            label: 'pointer-events-none absolute text-sm text-grey-300 transition-transform peer-focus:-translate-y-2 peer-focus:text-[10px] peer-focus:text-black peer-[&:not(:placeholder-shown)]:-translate-y-2 peer-[&:not(:placeholder-shown)]:text-[10px]',
            input: 'peer flex w-full items-center border border-grey-150 text-base focus:border-grey-200 disabled:border-grey-150 disabled:bg-grey-50 disabled:text-grey-300',
        },
        defaultVariants: {
            size: 'md',
        },
        compoundVariants: [
            {
                size: 'sm',
                label: true,
                className: {
                    label: 'left-2 top-2',
                    input: 'pb-1 pt-2',
                },
            },
            {
                size: 'md',
                label: true,
                className: {
                    label: 'left-2 top-2.5',
                    input: 'pb-1 pt-3',
                },
            },
            {
                size: 'lg',
                label: true,
                className: {
                    label: 'left-3 top-3 text-base',
                    input: 'px-3 pb-1 pt-4',
                },
            },
        ],
    },
    {
        responsiveVariants: true,
    },
);

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            onChange,
            className,
            initialValue,
            size,
            label,
            placeholder,
            icon,
            ...props
        },
        ref,
    ) => {
        const [value, setValue] = useState(initialValue ?? '');

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(event);
            }

            setValue(event.target.value);
        };

        const { input: inputSlot, label: labelSlot } = inputVariants({
            size,
            label: !!label,
        });

        return (
            <div className={cn('relative grid', className)}>
                <input
                    ref={ref}
                    className={inputSlot({
                        className: 'col-span-full row-span-full',
                    })}
                    onChange={handleChange}
                    placeholder={label ?? placeholder}
                    value={value}
                    {...props}
                />
                <span className="col-span-full row-span-full ml-auto mr-2 self-center">
                    {icon}
                </span>
                {label && <label className={labelSlot()}>{label}</label>}
            </div>
        );
    },
);

Input.displayName = 'Input';

export { Input };
